<template>
  <div
      v-if="isOpen"
      class="modal fade show"
      aria-labelledby="addNewCardTitle"
      style="display: block; background: #1c1d1c33"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div
                class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0"/>

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit">
            <div class="row gy-2 px-2">
              <div class="col-12">
                <label class="form-label" for="team_member_id">Team Member</label>
                <div>
                  <v-select
                      placeholder="Select Team Member"
                      v-model="memberForm.team_member_id"
                      :options="members"
                      label="name"
                      :reduce="name => name.id"
                  />
                  <ErrorMessage
                      name="member_id"
                      class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="team_role">Team Role</label>
                <div>
                  <v-select
                      placeholder="Select Team Member"
                      v-model="memberForm.team_role_id"
                      :options="roles"
                      label="label"
                      :reduce="name => name.id"
                  />
                  <ErrorMessage
                      name="member_id"
                      class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>

              <div class="col-12">
                <label class="form-label" for="designation">Designation</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="designation"
                      :value="memberForm.designation"
                      class="form-control add-credit-card-mask"
                      placeholder="Designation"
                      disabled
                  />
                  <ErrorMessage
                      name="code"
                      class="invalid-feedback d-block qcont"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2"/>
              <button
                  :disabled="loading"
                  type="submit"
                  class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
              >
                <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button
                  @click.prevent="toggleModal"
                  class="btn btn-outline-secondary mt-1 waves-effect"
              >
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import handleProjects from "@/services/modules/procurement/project";

export default {
  name: "TeamMemberModal",

  props: {
    modalTitle: {
      type: String,
      default: 'Add Team Member'
    },
  },

  data() {
    return {
      loading: false,
      isOpen: false,
      memberForm: {
        team_member_id: null,
        team_member_name: null,
        team_role_id: null,
        team_role_label: null,
        designation: null,
      },
      roles: [],
      members: [],
    };
  },

  watch: {
    'memberForm.team_member_id': function (newData) {
      this.memberForm.team_member_name = this.members.find(el => el.id == newData)?.name;
    },
    'memberForm.team_role_id': function (newData) {
      this.memberForm.team_role_label = this.roles.find(el => el.id == newData)?.label;
    },
  },

  methods: {
    submit() {
      this.$emit("onSubmitForm", {...this.memberForm});
      this.toggleModal();
    },

    resetForm() {
      this.memberForm = {
        team_member_id: null,
        team_member_name: null,
        team_role_id: null,
        team_role_name: null,
        designation: null,
      };
    },

    toggleModal() {
      this.isOpen = !this.isOpen;
      this.resetForm();
    },

    getQuery() {
      return `?company_id=${this.$route.params.companyId}`;
    }

  },

  setup() {
    const {
      fetchParties,
      fetchRoles,
    } = handleProjects();

    return {
      fetchParties,
      fetchRoles,
    }
  },

  mounted() {
    Promise.all([
      this.fetchParties(this.getQuery())
          .then(res => {
            this.members = res.data;
          }),
      this.fetchRoles(this.getQuery())
          .then(res => {
            this.roles = res.data;
          })
    ]);
  },
}
</script>